<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center content-wrapper" id="page-login">
    <div class="vx-col w-11/12 md:w-4/5 lg:w-1/2">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
           <div class="vx-row no-gutter justify-center items-center">
            <div class="vx-col w-full py-8 px-12 md:p-0 md:w-1/2 text-center">
              <img v-if="appName === 'FlipPay'" src="@/assets/images/logo/flippay-logo.svg" width="65%" height="auto" alt="Flippay" class="mx-auto"/>
              <img v-else-if="appName === 'RelloPay'" src="@/assets/images/logo/rellopay-logo.svg" width="65%" height="auto" alt="Rellopay" class="mx-auto"/>
              <div class="vx-card__title text-center pt-6 md:hidden">
                <h3 class="mb-4 text-xl md:text-2xl">Password reset successfully</h3>
                <p>Head back to the login page to login with your new password.</p>
              </div>
            </div>
            <div class="vx-col sm:w-full md:w-1/2">
              <div class="pt-6 pb-10 px-12 md:pt-10 md:pb-12 md:px-10 login-tabs-container bg-white">
                <div class="vx-card__title mb-12 md:mb-4 text-center md:text-left hidden md:block">
                  <h3 class="mb-4 text-xl md:text-2xl">Password reset successfully</h3>
                  <p>Head back to the login page to login with your new password.</p>
                </div>
                
                <div class="flex flex-col">
                  <vs-button to="/login" size="large" class="round w-full mb-0 mt-8" v-round>Back To Login</vs-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      appName: process.env.VUE_APP_NAME,
    }
  }
}
</script>

